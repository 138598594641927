import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import parse from 'html-react-parser';
import CoolDownApi from "../../services/CoolDownApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./CoolDown.css";
import {useTranslation} from "react-i18next";
import General from "../../utils/General";

//--------------------------------------------------

function CoolDown(props)
{
    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.coolDown.title), activeMenu: Menus.coolDown.className});

    //
    const [coolDownDataLoading, setCoolDownDataLoading] = useState(true);
    const [coolDownData, setCoolDownData] = useState(null);

    // load data
    const loadData = () =>
    {
        setCoolDownDataLoading(true); // show loading

        // get data
        CoolDownApi.show({language: General.getLanguage(i18n.language)}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setCoolDownData(response.data);

                    // video handler
                    setTimeout
                    (
                        function()
                        {
                            document.getElementById("btn-play").onclick = function()
                            {
                                // play video
                                const video = document.getElementById("video-player");
                                video.controls = true;
                                video.play();

                                // hide caption & btn
                                document.getElementById("caption").style.visibility = "hidden";
                                document.getElementById("btn-play").style.visibility = "hidden";

                                // remove class no-play of
                                const videoBox = document.getElementById("video-box");
                                videoBox.classList.remove("no-play");
                            }
                        },
                        1000
                    );
                }

                setCoolDownDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCoolDownDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    return (
        <div className="page-content page-cool-down">
            <div className="section-title">
                <div className="title-box">{parse(t('HowToCoolDown'))}</div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="page-description">{coolDownData != null ? parse(coolDownData.description) : null}</div>

                {/* loading */}
                {coolDownDataLoading ? <LoadingOver /> : null}
            </Scrollbars>
        </div>
    );
}

export default CoolDown;
