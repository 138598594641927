class General
{
    siteTitle = "Sports Diet";

    host = window.location.hostname;
    siteUrl = (this.host === "localhost" || this.host === "127.0.0.1") ? "http://127.0.0.1:8000" : "https://api.lynxmuscle.com";
    // siteUrl = (this.host === "localhost" || this.host === "127.0.0.1") ? "http://127.0.0.1/mohan21/shirazi/sports-diet/sports-diet-backend" : "https://api.lynxmuscle.com";
    apiUrl = this.siteUrl + "/api/pc";

    //
    localStorageName = "authenticatePC";

    // google reCaptcha Key
    reCaptchaKey = '6LdWVOIgAAAAAK6K3Adi92hN5zRHXDacWw7sDPea';

    // token
    authorizationToken = "";
    authorizationName = "";
    user = null;
    isRemember = false;

    // init status class
    initStatusClass = (status) =>
    {
        return status.replace(/ /g, '-').toLowerCase();
    };

    // init pageNumber
    initPageNumber = (paginationData) =>
    {
        let pageNumber = paginationData.currentPageNumber;

        if(paginationData.currentPageRows - 1 === 0)
        {
            pageNumber = (paginationData.currentPageNumber > 1) ? pageNumber - 1 : 0;
        }

        return pageNumber;
    };

    // check link
    isLink = (str) =>
    {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        return !!pattern.test(str);
    };

    // check email
    isEmail = (str) =>
    {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

        return !!pattern.test(str);
    };

    //
    handleInputOnlyEn = (e, op = false /* can persian */, language = 'en-US') =>
    {
        if((!op && /[^\x00-\x7F]+/.test(e.key)) || (op && language === 'en-US' && /[^\x00-\x7F]+/.test(e.key)))
        {
            e.preventDefault();
        }
    };

    //
    getDomain = (url) =>
    {
        try
        {
            const { hostname } = new URL(url);

            return hostname;
        }
        catch (e)
        {
            return '';
        }
    };

    //
    getLanguage = (language) =>
    {
        return language === "en-US" ? "en" : "fa";
    };

    // payment checkout
    paymentCheckout = (language, orderId) =>
    {
        return this.siteUrl + "/payment/checkout/" + language + "/" + orderId;
    };
}

export default new General();
