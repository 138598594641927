import { createContext, useContext } from 'react';

//--------------------------------------------------

export const PageContext = createContext
(
    {
        path: "",
        activeMenu: "",
        title: "",
        title2: "",
        pageName: "",
        pageData: () => {},
    }
);

export function usePage()
{
    return useContext(PageContext);
}
