import React, {useEffect, useRef, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import {TickSquare, Mobile, Eye, EyeSlash, UserEdit, Sms, Home} from 'iconsax-react';
import UserApi from "../../services/UserApi";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import imageBg01 from '../../images/bg-01.png';
import "./Login.css";
import { useTranslation } from 'react-i18next';
import General from "../../utils/General";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

//----------------------------------------------------------------------------------------------------------------------

function Login()
{
    const auth = useAuth();
    const { t, i18n } = useTranslation(); // translator
    const dialogAlert = useRef(); // init dialog alert

    const [formPhone, setFormPhone] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [isRemember, setIsRemember] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);
    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // send data to server
    const submitLogin = () =>
    {
        if(formPhone !== "" && formPassword !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'phone': formPhone, 'password': formPassword, 'reCaptchaToken': reCaptchaToken};
            UserApi.login(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        // change language to fa if phone have 98
                        i18n.changeLanguage((formPhone.substring(0, 3) === '+98' || formPhone.substring(0, 2) === '98') ? 'fa-IR' : 'en-US');

                        //
                        auth.authentication({user: response.data.user, name: response.data.name, token: response.data.token, isRemember: isRemember});
                        setIsLogin(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error

                    generateRecaptcha();
                }
            );
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formPhone === "") errors["formPhone"] = true;
            if(formPassword === "") errors["formPassword"] = true;

            setFormErrors(errors);
        }
    };

    // handle error
    const setErrorFormField = (field) =>
    {
        return (formErrors !== null && formErrors[field] !== undefined) ? " error" : "";
    };

    const removeErrorFormField = (field) =>
    {
        let errors = formErrors;
        if(errors !== null && errors[field] !== undefined) delete errors[field];

        setFormErrors({...errors});
    };

    // redirect to panel if user loggedIn
    if(isLogin)
    {
        return <Navigate to="/programs" />;
    }

    // page content ----------------------------------------------------------------------------------------------------
    return (
        <div className="page-login">
            <div className="col-left">
                <img src={imageBg01} />
            </div>

            <div className="col-right">
                <div className="section-top">
                    <div className="section-title">
                        <div className="title-box">
                            <span className="title">{t('Login')}</span>
                        </div>

                        <div className="link-box">
                            <a href="https://lynxmuscle.com/"><Home variant="Bulk" className="icon" /> {t('Home')}</a>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-input-box">
                                    <input type="number" className={"form-input" + setErrorFormField('formPhone')} onKeyPress={() => removeErrorFormField('formPhone')} placeholder={t('PhoneNumber') + '   989123219876'} onChange={(e) => setFormPhone(e.target.value)} />
                                    <Mobile variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box">
                                    <input type={passwordType} className={"form-input" + setErrorFormField('formPassword')} onKeyPress={(e) => {removeErrorFormField('formPassword'); General.handleInputOnlyEn(e);}} placeholder={t('Password')} autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                    {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-forget-remember">
                                    <label className="form-checkbox" onClick={() => setIsRemember(!isRemember)}>{isRemember ? <TickSquare variant="Bulk" className="icon" /> : <span className="icon"></span>} {t('RememberMe')}</label>

                                    <Link to="/forget-password" className="link-custom border-bottom">{t('ForgetPassword')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="btn-box">
                    <button onClick={submitLogin} type="button" className="btn-custom full">{t('Login')}</button>
                    <Link to="/register" className="btn-custom t2 full">{t('DontYouHaveAccountRegister')}</Link>
                </div>
            </div>

            {/* loading */}
            {formLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* dialog alert */}
            <DialogAlert ref={dialogAlert} />
        </div>
    );
}

export default Login;
