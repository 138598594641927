import React from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "./LoadingOver.css";

//
function LoadingOver(prop)
{
    //
    return(
        <Backdrop className="loading-over" sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
export default LoadingOver;
