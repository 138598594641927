import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useRef, useState} from "react";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import General from "../../utils/General";
import {useAuth} from "../../contexts/Auth";
import UserApi from "../../services/UserApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Profile.css";
import {Eye, EyeSlash, Sms, UserEdit} from "iconsax-react";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

//--------------------------------------------------

function Profile(props)
{
    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.profile.title), activeMenu: Menus.profile.className});

    //
    const auth = useAuth();
    const dialogAlert = useRef(); // init dialog alert

    const [formName, setFormName] = useState(General.user !== undefined ? General.user.name : "");
    const [formEmail, setFormEmail] = useState(General.user !== undefined ? General.user.email : "");
    const [formEmailTmp, setFormEmailTmp] = useState(General.user !== undefined ? General.user.email : "");
    const [formPassword, setFormPassword] = useState("");
    const [formPasswordNew, setFormPasswordNew] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeNew, setPasswordTypeNew] = useState("password");
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    // send data to server
    const submitProfile = () =>
    {
        if(formName !== "" && General.isEmail(formEmail) && formPassword !== "") // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'name': formName, 'email': formEmail, 'password': formPassword, 'password-new': formPasswordNew};
            UserApi.profile(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        dialogAlert.current.show(t('ProfileUpdated'), "", "success"); // show success
                        auth.authentication({user: response.data.user, name: response.data.name, token: response.data.token, isRemember: General.isRemember});
                        setFormEmailTmp(formEmail);
                    }
                    else
                    {
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error
                }
            );
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error"); // show error

            // check error
            let errors = {};
            if(formName === "") errors["formName"] = true;
            if(!General.isEmail(formEmail)) errors["formEmail"] = true;
            if(formPassword === "") errors["formPassword"] = true;

            setFormErrors(errors);
        }
    };

    // handle error
    const setErrorFormField = (field) =>
    {
        return (formErrors !== null && formErrors[field] !== undefined) ? " error" : "";
    };

    const removeErrorFormField = (field) =>
    {
        let errors = formErrors;
        if(errors !== null && errors[field] !== undefined) delete errors[field];

        setFormErrors({...errors});
    };

    //
    return (
        <div className="page-content page-profile">
            <div className="section-title">
                <div className="title-box">{parse(t('EditProfile'))}</div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-input-box">
                                <input type="text" className={"form-input" + setErrorFormField('formName')} onKeyPress={(e) => {removeErrorFormField('formName'); General.handleInputOnlyEn(e);}} placeholder={t('FullName')} value={formName} onChange={(e) => setFormName(e.target.value)} />
                                <UserEdit variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type="text" className={"form-input" + setErrorFormField('formEmail')} onKeyPress={(e) => {removeErrorFormField('formEmail'); General.handleInputOnlyEn(e);}} disabled={formEmailTmp !== ''} placeholder={t('Email')} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                                <Sms variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type={passwordTypeNew} className="form-input" onKeyPress={(e) => {General.handleInputOnlyEn(e);}} placeholder={t('NewPassword')} autoComplete="new-password-new" onChange={(e) => setFormPasswordNew(e.target.value)} />
                                {passwordTypeNew === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordTypeNew('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordTypeNew('password')} />}
                            </div>
                        </div>

                        <div className="col-12">
                            <hr className="hr" />
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type={passwordType} className={"form-input" + setErrorFormField('formPassword')} onKeyPress={(e) => {removeErrorFormField('formPassword'); General.handleInputOnlyEn(e);}} placeholder={t('CurrentPassword')} autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                            </div>
                        </div>
                    </div>
                </div>


                {/* loading */}
                {formLoading ? <LoadingOver /> : null}


                {/* dialog alert */}
                <DialogAlert ref={dialogAlert} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitProfile} type="button" className="btn-custom">{t('SaveChange')}</button>
            </div>
        </div>
    );
}

export default Profile;
