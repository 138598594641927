import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { AuthContext, useAuth } from "./contexts/Auth";
import { PageContext, usePage } from "./contexts/Page";
import Router from "./routes/Router";
import General from "./utils/General";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import UserApi from "./services/UserApi";
import "./styles/Main.css";
import "./styles/Main-fa.css";
import Menu from "./components/Menu/Menu";
// import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Send2, LogoutCurve, Home2, HambergerMenu } from 'iconsax-react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import LoadingOver from "./components/LoadingOver/LoadingOver";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

//----------------------------------------------------------------------------------------------------------------------

function App(props)
{
    // user auth
    const auth = useAuth();
    const uPage = usePage();
    const { t, i18n } = useTranslation(); // translator
    const [authenticated, setAuthenticated] = useState(auth.checkAuthenticate);
    const [name, setName] = useState(auth.getName);
    const authentication = (data) =>
    {
        auth.setAuthenticate(data);
        if(data !== undefined && data !== null) setName(data.name);
        setAuthenticated(data !== undefined && data !== null);
    };

    //
    const [pageLoading, setPageLoading] = useState(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    // page data :: title & path
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("");
    const [pageTitle2, setPageTitle2] = useState("");
    const [pageName, setPageName] = useState("");
    const [pagePath, setPagePath] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const pageData = (data) =>
    {
        uPage.pageTitle = data.title;
        uPage.pageTitle2 = data.title2;
        uPage.pageName = data.pageName;
        setPageTitle(data.title);
        setPageTitle2(data.title2);
        setPageName(data.pageName);
        setPagePath(data.path);
        setActiveMenu(data.activeMenu);

        auth.token();
    };

    // change language
    const [language, setLanguage] = useState("en");
    const changeLanguage = () =>
    {
        i18n.changeLanguage((i18n.language === "en-US") ? 'fa-IR' : 'en-US');

        setPageLoading(true);

        window.location.reload();
    };

    useEffect
    (
        () =>
        {
            if(i18n.language === 'fa-IR') document.body.classList.add('fa');
            else document.body.classList.remove('fa');

            setLanguage(i18n.language === "en-US" ? "en" : "fa");
        },
        [i18n.language]
    );

    // log out
    const logOut = () =>
    {
        UserApi.logout();
        authentication(null);
    };

    //
    return (
        <AuthContext.Provider value={{ authenticated: authenticated, authentication: authentication }}>
            <PageContext.Provider value={{ pageData: pageData }}>
                <GoogleReCaptchaProvider reCaptchaKey={General.reCaptchaKey}>
                    {
                        authenticated ?
                            (
                                <div className={(pageName !== "" ? "main-body main-body-" + pageName : "main-body")}>
                                    <Menu activeMenu={activeMenu} name={name} logOut={logOut} />

                                    <div className="col-content">
                                        <div className="top-bar">
                                            <div className="menu-drawer-breadcrumb">
                                                <button className="icon-box btn-menu-drawer" onClick={() => setIsOpenDrawer(true)}><HambergerMenu className="icon" variant="TwoTone" /></button>

                                                <ul className="breadcrumb">
                                                    <li><Link to=""><Home2 className="icon" />{t('Dashboard')}</Link></li>
                                                    <li>{pageTitle}</li>
                                                </ul>
                                            </div>

                                            <div className="col-icon">
                                                {/*<Tooltip title={t('Language')} placement="bottom" arrow><button className="icon-box" onClick={changeLanguage}>{language === "fa" ? parse('E<span className="char-color2">N</span>') : parse('F<span className="char-color2">A</span>')}</button></Tooltip>*/}
                                                <Tooltip title={t('Send')} placement="bottom" arrow><Link to="/chat" className="icon-box"><Send2 className="icon" variant="TwoTone" /></Link></Tooltip>
                                                <Tooltip title={t('Logout')} placement="bottom" arrow><button className="icon-box"><LogoutCurve className="icon" variant="TwoTone" onClick={logOut} /></button></Tooltip>
                                            </div>
                                        </div>

                                        <Router />
                                    </div>

                                    <Drawer className="menu-drawer" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
                                        <Menu activeMenu={activeMenu} logOut={logOut} />
                                    </Drawer>

                                    {/* loading */}
                                    {pageLoading ? <LoadingOver /> : null}
                                </div>
                            )
                            :
                            (
                                <div className={"main-body "}>
                                    <Router />
                                </div>
                            )
                    }
                </GoogleReCaptchaProvider>
            </PageContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
