import { createContext, useContext } from 'react';
import General from "../utils/General";

//--------------------------------------------------

export const AuthContext = createContext
(
    {
        authenticated: false,
        token: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            authenticate = (authenticate !== undefined && authenticate !== null) ? authenticate : JSON.parse(sessionStorage.getItem(General.localStorageName));

            General.authorizationToken = (authenticate !== undefined && authenticate !== null) ? {'Authorization': 'Bearer ' + authenticate.token} : "";
            General.authorizationName = (authenticate !== undefined && authenticate !== null) ? authenticate.name : "";
            General.user = (authenticate !== undefined && authenticate !== null) ? authenticate.user : null;
            General.isRemember = (authenticate !== undefined && authenticate !== null) ? authenticate.isRemember : null;
        },
        authentication: () => {},
        checkAuthenticate: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            authenticate = (authenticate !== undefined && authenticate !== null) ? authenticate : JSON.parse(sessionStorage.getItem(General.localStorageName));
            let authenticated = false;

            // check id & token exist then check token is valid
            if (authenticate !== undefined && authenticate !== null)
            {
                authenticated = true;
            }

            return authenticated;
        },
        getName: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            authenticate = (authenticate !== undefined && authenticate !== null) ? authenticate : JSON.parse(sessionStorage.getItem(General.localStorageName));

            return (authenticate !== undefined && authenticate !== null) ? authenticate.name : "";
        },
        getAuthenticate: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            authenticate = (authenticate !== undefined && authenticate !== null) ? authenticate : JSON.parse(sessionStorage.getItem(General.localStorageName));

            return authenticate;
        },
        setAuthenticate: (data) =>
        {
            if(data === null)
            {
                localStorage.setItem(General.localStorageName, JSON.stringify(data));
                sessionStorage.setItem(General.localStorageName, JSON.stringify(data));
            }
            else
            {
                if(data.isRemember)
                    localStorage.setItem(General.localStorageName, JSON.stringify(data));
                else
                    sessionStorage.setItem(General.localStorageName, JSON.stringify(data));
            }
        },
    }
);

export function useAuth()
{
    return useContext(AuthContext);
}
