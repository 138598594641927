import React, { forwardRef, useState, useImperativeHandle } from 'react';
import "./DialogAlert.css";
import imageDialogSuccess from '../../images/dialog-success.svg';
import imageDialogWarning from '../../images/dialog-warning.svg';
import imageDialogError from '../../images/dialog-error.svg';
import {CloseSquare} from 'iconsax-react';

//--------------------------------------------------

const DialogAlert = forwardRef
(
    (props, ref) =>
    {
        const [dialogShow, setDialogShow] = useState(false);
        const [dialogImage, setDialogImage] = useState('');
        const [dialogType, setDialogType] = useState('');
        const [dialogTitle, setDialogTitle] = useState('');
        const [dialogDes, setDialogDes] = useState('');
        const [dialogCallback, setDialogCallback] = useState('');

        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        show: (dialogTitle, dialogDes, dialogType, dialogCallback) =>
                        {
                            setDialogType(dialogType);
                            setDialogTitle(dialogTitle);
                            setDialogDes(dialogDes);
                            setDialogCallback(dialogCallback);

                            setDialogShow(true);

                            switch (dialogType)
                            {
                                case 'success': setDialogImage(imageDialogSuccess); break;
                                case 'error': setDialogImage(imageDialogError); break;
                                case 'warning': setDialogImage(imageDialogWarning); break;
                            }
                        },
                        hide: () => { setDialogShow(false); },
                    }
                )
        );

        //
        return(
            dialogShow ?
                <div className={dialogType !== '' ? "dialog-alert " + dialogType : "dialog-alert"}>
                    <div className="dialog-alert-content">
                        <CloseSquare onClick={() => { if(dialogCallback !== undefined) dialogCallback(); setDialogShow(false); }} className="dialog-alert-close" variant="TwoTone" />
                        <div className="dialog-alert-image"><img src={dialogImage} /></div>
                        {dialogTitle !== '' ? <div className="dialog-alert-title">{dialogTitle}</div> : null}
                        {dialogDes !== '' ? <div className="dialog-alert-des">{dialogDes}</div> : null}
                    </div>
                </div>
                :
                null
        );
    }
);

export default DialogAlert;
