import {UserSquare, HuobiToken, Sun, Messages1, Reserve, Weight, DocumentText, ClipboardClose} from 'iconsax-react';

export default
{
    newProgram: {className: "new-program", link: "/new-program", title: "MenuAddNewProgram", icon: <ClipboardClose className="icon" variant="TwoTone" />, isMenu: true},
    programs: {className: "programs", link: "/programs", title: "MenuMyPrograms", icon: <DocumentText className="icon" variant="TwoTone" />, isMenu: true},
    programWorkout: {className: "program-workout", link: "/program/workout", title: "MenuWorkoutPrograms", icon: <Weight className="icon" variant="TwoTone" />, isMenu: true},
    programFood: {className: "program-food", link: "/program/food", title: "MenuFoodPrograms", icon: <Reserve className="icon" variant="TwoTone" />, isMenu: true},
    chat: {className: "chat", link: "/chat", title: "MenuSupport", icon: <Messages1 className="icon" variant="TwoTone" />, isMenu: true},
    coolDown: {className: "cool-down", link: "/cool-down", title: "MenuHowToCoolDown", icon: <Sun className="icon" variant="TwoTone" />, isMenu: true},
    warmUp: {className: "warm-up", link: "/warm-up", title: "MenuHowToWarmUp", icon: <HuobiToken className="icon" variant="TwoTone" />, isMenu: true},
    profile: {className: "profile", link: "/profile", title: "MenuEditProfile", icon: <UserSquare className="icon" variant="TwoTone" />, isMenu: true},
};
