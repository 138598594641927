import React, {useState} from 'react';
import Menus from "../../utils/Menu";
import {Link} from "react-router-dom";
import {Scrollbars} from "react-custom-scrollbars-2";
import imageUser from "../../images/image-user.png";
import "./Menu.css";
import "./Menu-fa.css";
import {ArrowRight2} from "iconsax-react";
import {useTranslation} from "react-i18next";
import General from "../../utils/General";

//--------------------------------------------------

function Menu(props)
{
    const { t, i18n } = useTranslation(); // translator

    // active link of current page
    const checkPageLink = (currentPage, currentPageRegex = undefined) =>
    {
        if(props.activeMenu === currentPage)
        {
            return "active";
        }

        return null;
    };

    //
    return (
        <div className="col-menu">
            <div className="user-box">
                <div className="name-code-box">
                    <div className="user-name">{props.name}</div>
                    <div className="user-code">7707505939</div>
                </div>
                <img className="image" src={General.siteUrl + "/storage/app/public/upload/logo.png"} />
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="menu-scrollbar">
                <ul className="menu">
                    {
                        Object.keys(Menus).map
                        (
                            (key, i) => <li className={checkPageLink(Menus[key].className)} key={i}><Link to={Menus[key].link}>{Menus[key].icon}<span className="title">{t(Menus[key].title)}</span><ArrowRight2 className="icon-arrow" variant="TwoTone" /></Link></li>
                        )
                    }
                </ul>
            </Scrollbars>
        </div>
    );
}

export default Menu;
