import {usePage} from "../contexts/Page";
import '../styles/Main.css';

//--------------------------------------------------

function AqayePardakht(props)
{
    // set page title & page path
    usePage().pageData({title: "Aqaye Pardakht", activeMenu: 'AqayePardakht'});

    // page content
    return (
        <div className="page-content">

            Aqaye Pardakht
            <br />
            <a href={"http://127.0.0.1:8000/aqayepardakht/checkout/" + Math.round(Math.random() * 1000000)}>Pardakht</a>

        </div>
    );
}

export default AqayePardakht;
