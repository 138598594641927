import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./NewProgram.css";
import {
    Moneys,
    WalletMoney,
    MoneyAdd,
    Sms,
    UserEdit,
    User,
    ArrowDown2,
    Send2,
    Instagram,
    RulerPen,
    WeightMeter,
    ExportCurve,
    ArrowSquareRight,
    AddSquare
} from "iconsax-react";
// import imageProgramSpecial from "../../images/program-special.png";
// import imageProgramNonSpecial from "../../images/program-non-special.png";
// import imageProgramAtHome from "../../images/program-at-home.png";
// import imageProgramAtGym from "../../images/program-at-gym.png";
// import imageProgramWorkout from "../../images/program-workout.png";
// import imageProgramFood from "../../images/program-food.png";
import imageIconBodyFront from "../../images/icon-body-front.png";
import imageIconBodyBack from "../../images/icon-body-back.png";
import imageIconBodyRightSide from "../../images/icon-body-right-side.png";
import imageIconBodyLeftSide from "../../images/icon-body-left-side.png";
import General from "../../utils/General";
import OrderApi from "../../services/OrderApi";
import ProgramApi from "../../services/ProgramApi";
import CountryApi from "../../services/CountryApi";
import CoachApi from "../../services/CoachApi";
import CityApi from "../../services/CityApi";
import parse from "html-react-parser";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import imageProgramList from "../../images/bg-program-list.png";
import imageProgramGym from "../../images/bg-program-gym.png";
import imageProgramHome from "../../images/bg-program-home.png";
import imageProgramChampion from "../../images/bg-program-champion.png";
import imageProgramFood from "../../images/bg-program-food.png";
import {useAuth} from "../../contexts/Auth";

//--------------------------------------------------

function NewProgram(props)
{
    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.newProgram.title), activeMenu: Menus.newProgram.className});

    //
    const dialogAlert = useRef(); // init dialog alert
    const navigate = useNavigate();
    const auth = useAuth();

    const [pageTitle, setPageTitle] = useState("");
    const [pageDes, setPageDes] = useState("");

    const [programsDataLoading, setProgramsDataLoading] = useState(false);
    const [countryDataLoading, setCountryDataLoading] = useState(false);
    const [coachDataLoading, setCoachDataLoading] = useState(false);

    const [countryData, setCountryData] = useState(null);
    const [coachData, setCoachData] = useState(null);
    const [cityData, setCityData] = useState(null);

    const [formLoading, setFormLoading] = useState(false);
    const [stepNumber, setStepNumber] = useState(3);

    const [formProgramId, setFormProgramId] = useState(0);
    const [formProgramSpecial, setFormProgramSpecial] = useState(-1);
    const [formProgramType, setFormProgramType] = useState(-1);
    const [formProgramTrainingAt, setFormProgramTrainingAt] = useState(-1);
    const [formName, setFormName] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formCountry, setFormCountry] = useState("");
    const [formCoach, setFormCoach] = useState("");
    const [formCity, setFormCity] = useState("");
    const [formAge, setFormAge] = useState("");
    const [formGender, setFormGender] = useState("");
    const [formGainLoss, setFormGainLoss] = useState("");
    const [formTrainingHistory, setFormTrainingHistory] = useState("");
    const [formSurgeries, setFormSurgeries] = useState("");
    const [formFoodAllergies, setFormFoodAllergies] = useState("");
    const [formGoal, setFormGoal] = useState("");
    const [formMoreDetail, setFormMoreDetail] = useState("");
    const [formReferral, setFormReferral] = useState("");
    const [formTelegramID, setFormTelegramID] = useState("");
    const [formInstagramID, setFormInstagramID] = useState("");
    const [formHeight, setFormHeight] = useState("");
    const [formWeight, setFormWeight] = useState("");
    const [formBloodGroup, setFormBloodGroup] = useState("");
    const [formTrainingWeek, setFormTrainingWeek] = useState("");
    const [formDiseaseBackground, setFormDiseaseBackground] = useState("");
    const [formDiseaseBackgroundDes, setFormDiseaseBackgroundDes] = useState("");
    const [formSmoke, setFormSmoke] = useState("");
    const [formImageFront, setFormImageFront] = useState("");
    const [formImageBack, setFormImageBack] = useState("");
    const [formImageRightSide, setFormImageRightSide] = useState("");
    const [formImageLeftSide, setFormImageLeftSide] = useState("");
    const [formImageFrontSrc, setFormImageFrontSrc] = useState(imageIconBodyFront);
    const [formImageBackSrc, setFormImageBackSrc] = useState(imageIconBodyBack);
    const [formImageRightSideSrc, setFormImageRightSideSrc] = useState(imageIconBodyRightSide);
    const [formImageLeftSideSrc, setFormImageLeftSideSrc] = useState(imageIconBodyLeftSide);
    const [formImageReceipt, setFormImageReceipt] = useState("");
    const [formErrors, setFormErrors] = useState(null);

    const [programData, setProgramData] = useState(null);
    const [programPrice, setProgramPrice] = useState(0);
    const [programPriceTax, setProgramPriceTax] = useState(0);
    const [programPriceTotal, setProgramPriceTotal] = useState(0);
    const [programTax, setProgramTax] = useState(0);

    // load data
    const loadCountryData = () =>
    {
        setCountryDataLoading(true); // show loading

        // get data
        CountryApi.list().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setCountryData(response.data.list);
                }

                setCountryDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCountryDataLoading(false); // hide loading
            }
        );
    };

    const loadCoachData = () =>
    {
        setCoachDataLoading(true); // show loading

        // get data
        CoachApi.list({language: General.getLanguage(i18n.language)}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setCoachData(response.data.list);
                }

                setCoachDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCoachDataLoading(false); // hide loading
            }
        );
    };

    const loadProgramData = () =>
    {
        // get price data
        setProgramsDataLoading(true); // show loading
        ProgramApi.list({language: General.getLanguage(i18n.language)}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setProgramData(response.data.list);
                }
                // else
                // {
                //     dialogAlert.current.show(t(response.message), "", "error"); // show error
                // }

                setProgramsDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramsDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { updatePageTitle(); loadCountryData(); loadCoachData(); loadProgramData(); }, []);

    // update page title
    const updatePageTitle = () =>
    {
        setPageTitle(t('AddNewProgram'));

        // switch (stepNumber)
        // {
        //     // case 1: setPageTitle(t('AddNewProgram')); break;
        //     // case 2: setPageTitle(formProgramSpecial === 1 ? t('SpecializedProgram') : t('NonSpecializedProgram')); break;
        //     // case 3: setPageTitle(formProgramType === 1 ? t('WorkoutProgram') : t('FoodProgram')); break;
        //     case 3: setPageTitle(t('AddNewProgram')); break;
        //     default: setPageTitle(formProgramTrainingAt === 1 ? t('TrainingProgramGym') : t('TrainingProgramHome')); break;
        // }
    };

    // useEffect(() => { updatePageTitle(); }, [stepNumber]);

    // handle steps
    const handleStepBack = () =>
    {
        setStepNumber(stepNumber - 1);
    };

    const handleStep4 = () =>
    {
        if(formName !== "" && General.isEmail(formEmail) && formCountry !== "" && formCity !== "" && formCoach !== "" && formAge !== "" && formGender !== "" && formGainLoss !== "")
        {
            setStepNumber(5);
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formName === "") errors["formName"] = true;
            if(!General.isEmail(formEmail)) errors["formEmail"] = true;
            if(formCountry === "") errors["formCountry"] = true;
            if(formCoach === "") errors["formCoach"] = true;
            if(formCity === "") errors["formCity"] = true;
            if(formAge === "") errors["formAge"] = true;
            if(formGender === "") errors["formGender"] = true;
            if(formGainLoss === "") errors["formGainLoss"] = true;

            setFormErrors(errors);
        }
    };

    const handleStep5 = () =>
    {
        if(/*formTelegramID !== "" && formInstagramID !== "" && */formHeight !== "" && formWeight !== "" && formBloodGroup !== "" && formTrainingWeek !== "" /*&& formDiseaseBackground !== "" && (formDiseaseBackground === 0 || (formDiseaseBackground === 1 && formDiseaseBackgroundDes !== ""))*/ && formSmoke !== "")
        {
            setStepNumber(6);
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            // if(formTelegramID === "") errors["formTelegramID"] = true;
            // if(formInstagramID === "") errors["formInstagramID"] = true;
            if(formHeight === "") errors["formHeight"] = true;
            if(formWeight === "") errors["formWeight"] = true;
            if(formBloodGroup === "") errors["formBloodGroup"] = true;
            if(formTrainingWeek === "") errors["formTrainingWeek"] = true;
            // if(formDiseaseBackground === "") errors["formDiseaseBackground"] = true;
            // if(formDiseaseBackground === 1 && formDiseaseBackgroundDes === "") errors["formDiseaseBackgroundDes"] = true;
            if(formSmoke === "") errors["formSmoke"] = true;

            setFormErrors(errors);
        }
    };

    const handleStep6 = () =>
    {
        if(formTrainingHistory !== "" && formSurgeries !== "" && formFoodAllergies !== "")
        {
            setStepNumber(7);
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formTrainingHistory === "") errors["formTrainingHistory"] = true;
            if(formSurgeries === "") errors["formSurgeries"] = true;
            if(formFoodAllergies === "") errors["formFoodAllergies"] = true;

            setFormErrors(errors);
        }
    };

    const handleStep7 = () =>
    {
        if(formGoal !== ""/* && formMoreDetail !== ""*/)
        {
            setStepNumber(8);
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formGoal === "") errors["formGoal"] = true;
            // if(formMoreDetail === "") errors["formMoreDetail"] = true;

            setFormErrors(errors);
        }
    };

    const handleStep8 = () =>
    {
        if(formImageFront !== "" && formImageBack !== "" && formImageRightSide !== "" && formImageLeftSide !== "")
        {
            setStepNumber(9);
            /*
            // get price data
            setNewProgramDataLoading(true); // show loading
            ProgramApi.price({'formProgramSpecial': formProgramSpecial, 'formProgramType': formProgramType, 'formProgramTrainingAt': formProgramTrainingAt, 'formGender': formGender, 'formGainLoss': formGainLoss}).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        setProgramPrice(response.data.price);
                        setProgramPriceTax(response.data.priceTax);
                        setProgramPriceTotal(response.data.priceTotal);
                        setProgramTax(response.data.tax);

                        setStepNumber(9);
                    }
                    else
                    {
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    setNewProgramDataLoading(false); // hide loading
                }
            ).catch
            (
                function(error)
                {
                    setNewProgramDataLoading(false); // hide loading
                }
            );
            */
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formImageFront === "") errors["formImageFront"] = true;
            if(formImageBack === "") errors["formImageBack"] = true;
            if(formImageRightSide === "") errors["formImageRightSide"] = true;
            if(formImageLeftSide === "") errors["formImageLeftSide"] = true;

            setFormErrors(errors);
        }
    };

    const handleStep9 = () =>
    {
        //if(formImageReceipt !== "")
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('formProgramId', formProgramId);
            params.append('formProgramSpecial', formProgramSpecial);
            params.append('formProgramType', formProgramType);
            params.append('formProgramTrainingAt', formProgramTrainingAt);
            params.append('formName', formName);
            params.append('formEmail', formEmail);
            params.append('formCoach', formCoach);
            params.append('formCountry', formCountry);
            params.append('formCity', formCity);
            params.append('formAge', formAge);
            params.append('formGender', formGender);
            params.append('formGainLoss', formGainLoss);
            params.append('formTrainingHistory', formTrainingHistory);
            params.append('formSurgeries', formSurgeries);
            params.append('formFoodAllergies', formFoodAllergies);
            params.append('formGoal', formGoal);
            params.append('formMoreDetail', formMoreDetail);
            params.append('formReferral', formReferral);
            params.append('formTelegramID', formTelegramID);
            params.append('formInstagramID', formInstagramID);
            params.append('formHeight', formHeight);
            params.append('formWeight', formWeight);
            params.append('formBloodGroup', formBloodGroup);
            params.append('formTrainingWeek', formTrainingWeek);
            params.append('formDiseaseBackground', formDiseaseBackground);
            params.append('formDiseaseBackgroundDes', formDiseaseBackgroundDes);
            params.append('formSmoke', formSmoke);
            params.append('formImageFront', formImageFront);
            params.append('formImageBack', formImageBack);
            params.append('formImageRightSide', formImageRightSide);
            params.append('formImageLeftSide', formImageLeftSide);
            params.append('formImageReceipt', formImageReceipt);

            OrderApi.store(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        dialogAlert.current.show(t('NewProgramSuccessTitle'), t('NewProgramSuccessDes'), "success", goToPrograms); // show success
                        //auth.authentication({user: response.data.user, name: response.data.name, token: response.data.token, isRemember: General.isRemember});

                        // go to Payment Gateway
                        window.location.href = General.paymentCheckout(General.getLanguage(i18n.language), response.data.orderId);
                    }
                    else
                    {
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    setFormLoading(false); // hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // hide loading
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error
                }
            );
        }
        /*
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error");

            // check error
            let errors = {};
            if(formImageReceipt === "") errors["formImageReceipt"] = true;

            setFormErrors(errors);
        }
        */
    };

    // handle image
    const handleImageChange = (event, type) =>
    {
        if(event.target.files && event.target.files[0])
        {
            let reader = new FileReader();
            reader.onload = (e) =>
            {
                switch(type)
                {
                    case 'front': setFormImageFront(event.target.files[0]); setFormImageFrontSrc(e.target.result); break;
                    case 'back': setFormImageBack(event.target.files[0]); setFormImageBackSrc(e.target.result); break;
                    case 'rightSide': setFormImageRightSide(event.target.files[0]); setFormImageRightSideSrc(e.target.result); break;
                    case 'leftSide': setFormImageLeftSide(event.target.files[0]); setFormImageLeftSideSrc(e.target.result); break;
                    case 'receipt': setFormImageReceipt(event.target.files[0]); break;
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    // handle country
    const handleCountryChange = (e) =>
    {
        let countryId = e.target.value;

        setFormCountry(countryId);
        setFormCity("");
        setCityData(null);

        if(countryId > 0)
        {
            setCountryDataLoading(true); // show loading

            // get data
            CityApi.list({'country': countryId}).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        setCityData(response.data.list);
                    }

                    setCountryDataLoading(false); // hide loading
                }
            ).catch
            (
                function(error)
                {
                    setCountryDataLoading(false); // hide loading
                }
            );
        }
    };

    // handle error
    const setErrorFormField = (field) =>
    {
        return (formErrors !== null && formErrors[field] !== undefined) ? " error" : "";
    };

    const removeErrorFormField = (field) =>
    {
        let errors = formErrors;
        if(errors !== null && errors[field] !== undefined) delete errors[field];

        setFormErrors({...errors});
    };

    //
    const handleProgramSelected = (program) =>
    {
        setFormProgramId(program.id);
        setProgramPrice(program.price);
        setProgramPriceTax(program.priceTax);
        setProgramPriceTotal(program.priceTotal);
        setProgramTax(program.tax);

        switch (program.trainingAt)
        {
            case 0:
                setPageTitle(program.foodWorkout === 0 ? t('NewProgramFoodHomeTitle') : t('NewProgramWorkoutHomeTitle'));
                setPageDes(program.foodWorkout === 0 ? t('NewProgramFoodHomeDes') : t('NewProgramWorkoutHomeDes'));
                break;

            case 1:
                setPageTitle(t('NewProgramWorkoutGymTitle'));
                setPageDes(t('NewProgramWorkoutGymDes'));
                break;

            case 2:
                setPageTitle(t('NewProgramWorkoutChampionTitle'));
                setPageDes(t('NewProgramWorkoutChampionDes'));
                break;
        }

        setStepNumber(4);
    };

    // redirect to programs
    const goToPrograms = () => () =>
    {
        navigate("/programs", { replace: false });
    };

    //
    return (
        <div className="page-content page-new-program">
            <div className="section-title">
                <div className="title-box">{parse(pageTitle)}</div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {/* step 1 */}
                {/*
                <div className={stepNumber === 1 ? "step-box by-program-item" : "display-none"}>
                    <div className="page-description">{t('NewProgramDesStep1')}</div>

                    <div className="row row-program-item">
                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramSpecial} />
                                    <div className="title">{t('SpecializedProgram2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramSpecial(1); setStepNumber(2); }}>{t('Next')}</button>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramNonSpecial} />
                                    <div className="title">{t('NonSpecializedProgram2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramSpecial(0); setStepNumber(2); }}>{t('Next')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                {/* step 2 */}
                {/*
                <div className={stepNumber === 2 ? "step-box by-program-item" : "display-none"}>
                    <div className="page-description">{t('NewProgramDesStep2')}</div>

                    <div className="row row-program-item">
                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramWorkout} />
                                    <div className="title">{t('WorkoutProgram2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramType(1); setStepNumber(3); }}>{t('Next')}</button>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramFood} />
                                    <div className="title">{t('FoodProgram2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramType(0); setStepNumber(3); }}>{t('Next')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                */}

                {/* step 3 */}
                {/*
                <div className={stepNumber === 3 ? "step-box by-program-item" : "display-none"}>
                    <div className="page-description">{t('NewProgramDesStep3')}</div>

                    <div className="row row-program-item">
                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramAtHome} />
                                    <div className="title">{t('TrainingProgramHome2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramTrainingAt(0); setStepNumber(4); }}>{t('Next')}</button>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="program-item">
                                <div className="image-title">
                                    <img src={imageProgramAtGym} />
                                    <div className="title">{t('TrainingProgramGym2')}</div>
                                </div>

                                <button type="button" className="btn-custom full" onClick={() => { setFormProgramTrainingAt(1); setStepNumber(4); }}>{t('Next')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                */}

                {/* step 3 */}
                <div className={stepNumber === 3 ? "step-box by-program-item" : "display-none"}>
                    <div className="page-description">{t('NewProgramDesStep3')}</div>

                    <div className="program-items">
                    <div className="row row-program-item">
                        {
                            programData != null && programData.length > 0 ?
                                programData.map
                                (
                                    (program, i) =>
                                        <div className="col-4">
                                            <div className="item" onClick={() => handleProgramSelected(program)}>
                                                <div className="image-title-des">
                                                    <img src={program.image} />
                                                    <div className="title">{program.title}</div>
                                                    {/*{*/}
                                                    {/*    {*/}
                                                    {/*        0: program.foodWorkout === 0 ? <img src={imageProgramFood} /> : <img src={imageProgramHome} />,*/}
                                                    {/*        1: <img src={imageProgramGym} />,*/}
                                                    {/*        2: <img src={imageProgramChampion} />,*/}
                                                    {/*    }[program.trainingAt]*/}
                                                    {/*}*/}
                                                    {/*<div className="title">*/}
                                                    {/*    {*/}
                                                    {/*        {*/}
                                                    {/*            0: t('TrainingProgramHome2'),*/}
                                                    {/*            1: t('TrainingProgramGym2'),*/}
                                                    {/*            2: t('TrainingProgramChampions2'),*/}
                                                    {/*        }[program.trainingAt]*/}
                                                    {/*    }*/}
                                                    {/*</div>*/}
                                                    <div className="des">{program.foodWorkout === 0 ? t('FoodProgram2') : t('WorkoutProgram2')}<br />{t('OnlineSupport')}{/*<br />2 Month training program<br />Online support*/}</div>
                                                </div>

                                                <div className="price-box">{parse(t('Price'))}: {program.priceTotal} {t('PriceUnit')}<AddSquare className="icon" variant="Bulk" /></div>
                                                {/*<button type="button" className="btn-custom t2 full" onClick={() => handleGoToShowOrder(i)}>{parse(t('ShowMyProgram'))}<ArrowSquareRight className="icon" variant="Bulk" /></button>*/}
                                            </div>
                                        </div>
                                )
                                :
                                null
                        }
                    </div>
                    </div>
                </div>

                {/* step 4 */}
                <div className={stepNumber === 4 ? "step-box" : "display-none"}>
                    <div className="page-description">{pageDes}</div>

                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="text" className={"form-input" + setErrorFormField('formName')} onKeyPress={(e) => {removeErrorFormField('formName'); General.handleInputOnlyEn(e, true, i18n.language);}} placeholder={t('FullName')} value={formName} onChange={(e) => setFormName(e.target.value)} />
                                    <UserEdit variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="text" className={"form-input" + setErrorFormField('formEmail')} onKeyPress={(e) => {removeErrorFormField('formEmail'); General.handleInputOnlyEn(e);}} placeholder={t('Email')} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                                    <Sms variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formCountry === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formCountry')} onChange={(e) => {handleCountryChange(e); removeErrorFormField('formCountry');}}>
                                        <option value="">{t('Country')}</option>
                                        {
                                            countryData != null && countryData.length > 0 ?
                                                countryData.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <option className="normal" value={item.id} key={item.id}>{item.name}</option>
                                                        )
                                                    }
                                                )
                                                :
                                                null
                                        }
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formCity === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formCity')} onChange={(e) => {setFormCity(e.target.value); removeErrorFormField('formCity');}}>
                                        <option value="">{t('City')}</option>
                                        {
                                            cityData != null && cityData.length > 0 ?
                                                cityData.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <option className="normal" value={item.id} key={item.id}>{item.name}</option>
                                                        )
                                                    }
                                                )

                                                :
                                                null
                                        }
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="number" className={"form-input" + setErrorFormField('formAge')} onKeyPress={(e) => {removeErrorFormField('formAge'); General.handleInputOnlyEn(e);}} placeholder={t('Age')} value={formAge} onChange={(e) => setFormAge(e.target.value)} />
                                    <User variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formGender === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formGender')} onChange={(e) => {setFormGender(e.target.value); removeErrorFormField('formGender');}}>
                                        <option value="">{t('Gender')}</option>
                                        <option className="normal" value="1">{t('Man')}</option>
                                        <option className="normal" value="2">{t('Woman')}</option>
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formGainLoss === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formGainLoss')} onChange={(e) => {setFormGainLoss(e.target.value); removeErrorFormField('formGainLoss');}}>
                                        <option value="">{t('GainLoss')}</option>
                                        <option className="normal" value="1">{t('Gain')}</option>
                                        <option className="normal" value="2">{t('Loss')}</option>
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                                {/*<div className="form-input-box display-flex">*/}
                                {/*    <div className={"form-label" + setErrorFormField('formGainLoss')}>{t('GainLoss')}:</div>*/}
                                {/*    <label className="input-radio-box w-140"><input type="radio" checked={formGainLoss === 0} onChange={(e) => {setFormGainLoss(0); removeErrorFormField('formGainLoss');}} /> {t('Gain')}</label>*/}
                                {/*    <label className="input-radio-box w-140"><input type="radio" checked={formGainLoss === 1} onChange={(e) => {setFormGainLoss(1); removeErrorFormField('formGainLoss');}} /> {t('Loss')}</label>*/}
                                {/*</div>*/}
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formCoach === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formCoach')} onChange={(e) => {setFormCoach(e.target.value); removeErrorFormField('formCoach');}}>
                                        <option value="">{t('Coach')}</option>
                                        {
                                            coachData != null && coachData.length > 0 ?
                                                coachData.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <option className="normal" value={item.id} key={item.id}>{item.title}</option>
                                                        )
                                                    }
                                                )
                                                :
                                                null
                                        }
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 5 */}
                <div className={stepNumber === 5 ? "step-box" : "display-none"}>
                    <div className="page-description">{pageDes}</div>

                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="number" className={"form-input" + setErrorFormField('formHeight')} onKeyPress={(e) => {removeErrorFormField('formHeight'); General.handleInputOnlyEn(e);}} placeholder={t('Height')} value={formHeight} onChange={(e) => setFormHeight(e.target.value)} />
                                    <RulerPen variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="number" className={"form-input" + setErrorFormField('formWeight')} onKeyPress={(e) => {removeErrorFormField('formWeight'); General.handleInputOnlyEn(e);}} placeholder={t('Weight')} value={formWeight} onChange={(e) => setFormWeight(e.target.value)} />
                                    <WeightMeter variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formBloodGroup === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formBloodGroup')} onChange={(e) => {setFormBloodGroup(e.target.value); removeErrorFormField('formBloodGroup');}}>
                                        <option value="">{t('BloodGroup')}</option>
                                        <option className="normal" value="1">O+</option>
                                        <option className="normal" value="2">O-</option>
                                        <option className="normal" value="3">A+</option>
                                        <option className="normal" value="4">A-</option>
                                        <option className="normal" value="5">B+</option>
                                        <option className="normal" value="6">B-</option>
                                        <option className="normal" value="7">AB+</option>
                                        <option className="normal" value="8">AB-</option>
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formTrainingWeek === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formTrainingWeek')} onChange={(e) => {setFormTrainingWeek(e.target.value); removeErrorFormField('formTrainingWeek');}}>
                                        <option value="">{t('TrainingInWeek')}</option>
                                        <option className="normal" value="1">5</option>
                                        <option className="normal" value="2">10</option>
                                        <option className="normal" value="3">15</option>
                                        <option className="normal" value="4">20</option>
                                        <option className="normal" value="5">25</option>
                                        <option className="normal" value="6">30</option>
                                        <option className="normal" value="7">35</option>
                                        <option className="normal" value="8">40</option>
                                        <option className="normal" value="9">45</option>
                                        <option className="normal" value="10">50</option>
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            {/*<div className="col-6">*/}
                            {/*    <div className="form-input-box display-flex m-b-24">*/}
                            {/*        <div className={"form-label" + setErrorFormField('formDiseaseBackground')}>{t('DiseaseBackground')}:</div>*/}
                            {/*        <label className="input-radio-box w-140"><input type="radio" checked={formDiseaseBackground === 1} onChange={(e) => {setFormDiseaseBackground(1); removeErrorFormField('formDiseaseBackground');}} /> {t('Yes')}</label>*/}
                            {/*        <label className="input-radio-box w-140"><input type="radio" checked={formDiseaseBackground === 0} onChange={(e) => {setFormDiseaseBackground(0); removeErrorFormField('formDiseaseBackground');}} /> {t('No')}</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-6">*/}
                            {/*    <div className="form-input-box display-flex m-b-24">*/}
                            {/*        <div className={"form-label" + setErrorFormField('formSmoke')}>{t('Smoke')}:</div>*/}
                            {/*        <label className="input-radio-box w-140"><input type="radio" checked={formSmoke === 1} onChange={(e) => {setFormSmoke(1); removeErrorFormField('formSmoke');}} /> {t('Yes')}</label>*/}
                            {/*        <label className="input-radio-box w-140"><input type="radio" checked={formSmoke === 0} onChange={(e) => {setFormSmoke(0); removeErrorFormField('formSmoke');}} /> {t('No')}</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="col-6">
                                <div className="form-input-box">
                                    <select className={(formSmoke === "" ? "form-select placeholder" : "form-select") + setErrorFormField('formSmoke')} onChange={(e) => {setFormSmoke(e.target.value); removeErrorFormField('formSmoke');}}>
                                        <option value="">{t('Smoke')}</option>
                                        <option className="normal" value="1">{t('Yes')}</option>
                                        <option className="normal" value="0">{t('No')}</option>
                                    </select>
                                    <ArrowDown2 variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            {/*{*/}
                            {/*    formDiseaseBackground === 1 ?*/}
                            {/*        <div className="col-12">*/}
                            {/*            <div className="form-input-box no-icon">*/}
                            {/*                <textarea className={"form-textarea h-80" + setErrorFormField('formDiseaseBackgroundDes')} onKeyPress={(e) => {removeErrorFormField('formDiseaseBackgroundDes'); General.handleInputOnlyEn(e);}} value={formDiseaseBackgroundDes} placeholder={t('Disease Background Description')} onChange={(e) => setFormDiseaseBackgroundDes(e.target.value)}/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        :*/}
                            {/*        null*/}
                            {/*}*/}

                            {/*<div className="col-6">*/}
                            {/*    <div className="form-input-box">*/}
                            {/*        <input type="text" className={"form-input" + setErrorFormField('formTelegramID')} onKeyPress={(e) => {removeErrorFormField('formTelegramID'); General.handleInputOnlyEn(e);}} placeholder={t('TelegramID')} value={formTelegramID} onChange={(e) => setFormTelegramID(e.target.value)} />*/}
                            {/*        <Send2 variant="TwoTone" className="icon" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="col-6">
                                <div className="form-input-box">
                                    <input type="text" className={"form-input" + setErrorFormField('formInstagramID')} onKeyPress={(e) => {removeErrorFormField('formInstagramID'); General.handleInputOnlyEn(e);}} placeholder={t('InstagramID')} value={formInstagramID} onChange={(e) => setFormInstagramID(e.target.value)} />
                                    <Instagram variant="TwoTone" className="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 6 */}
                <div className={stepNumber === 6 ? "step-box" : "display-none"}>
                    <div className="page-description">{pageDes}</div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-80" + setErrorFormField('formTrainingHistory')} onKeyPress={(e) => {removeErrorFormField('formTrainingHistory'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formTrainingHistory} placeholder={t('TrainingHistory')} onChange={(e) => setFormTrainingHistory(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-80" + setErrorFormField('formSurgeries')} onKeyPress={(e) => {removeErrorFormField('formSurgeries'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formSurgeries} placeholder={t('HaveYouHadAnySurgeries')} onChange={(e) => setFormSurgeries(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-80" + setErrorFormField('formFoodAllergies')} onKeyPress={(e) => {removeErrorFormField('formFoodAllergies'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formFoodAllergies} placeholder={t('FoodAllergies')} onChange={(e) => setFormFoodAllergies(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-80" + setErrorFormField('formDiseaseBackgroundDes')} onKeyPress={(e) => {removeErrorFormField('formDiseaseBackgroundDes'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formDiseaseBackgroundDes} placeholder={t('Disease Background Description')} onChange={(e) => setFormDiseaseBackgroundDes(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 7 */}
                <div className={stepNumber === 7 ? "step-box" : "display-none"}>
                    <div className="page-description">{pageDes}</div>

                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-100" + setErrorFormField('formGoal')} onKeyPress={(e) => {removeErrorFormField('formGoal'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formGoal} placeholder={t('YourGoal')} onChange={(e) => setFormGoal(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box no-icon">
                                    <textarea className={"form-textarea h-100" + setErrorFormField('formMoreDetail')} onKeyPress={(e) => {removeErrorFormField('formMoreDetail'); General.handleInputOnlyEn(e, true, i18n.language);}} value={formMoreDetail} placeholder={t('MoreDetail')} onChange={(e) => setFormMoreDetail(e.target.value)}/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box">
                                    <input type="text" className="form-input" onKeyPress={(e) => {General.handleInputOnlyEn(e);}} placeholder={t('YourReferral')} value={formReferral} onChange={(e) => setFormReferral(e.target.value)} />
                                    <Sms variant="TwoTone" className="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 8 */}
                <div className={stepNumber === 8 ? "step-box" : "display-none"}>
                    <div className="page-description">{pageDes}</div>

                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-input-box">
                                    <label className={"btn-image-file" + setErrorFormField('formImageFront')} htmlFor="form-image-front">
                                        <img src={formImageFrontSrc} />
                                        {t('UploadPictureFront')}
                                        <div className="icon-box"><ExportCurve className="icon" variant="TwoTone" /></div>
                                        <input type="file" id="form-image-front" onChange={(e) => {handleImageChange(e, 'front'); removeErrorFormField('formImageFront');}} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <label className={"btn-image-file" + setErrorFormField('formImageBack')} htmlFor="form-image-back">
                                        <img src={formImageBackSrc} />
                                        {t('UploadPictureBack')}
                                        <div className="icon-box"><ExportCurve className="icon" variant="TwoTone" /></div>
                                        <input type="file" id="form-image-back" onChange={(e) => {handleImageChange(e, 'back'); removeErrorFormField('formImageBack');}} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <label className={"btn-image-file" + setErrorFormField('formImageRightSide')} htmlFor="form-image-right-side">
                                        <img src={formImageRightSideSrc} />
                                        {t('UploadPictureRightSide')}
                                        <div className="icon-box"><ExportCurve className="icon" variant="TwoTone" /></div>
                                        <input type="file" id="form-image-right-side" onChange={(e) => {handleImageChange(e, 'rightSide'); removeErrorFormField('formImageRightSide');}} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-input-box">
                                    <label className={"btn-image-file" + setErrorFormField('formImageLeftSide')} htmlFor="form-image-left-side">
                                        <img src={formImageLeftSideSrc} />
                                        {t('UploadPictureLeftSide')}
                                        <div className="icon-box"><ExportCurve className="icon" variant="TwoTone" /></div>
                                        <input type="file" id="form-image-left-side" onChange={(e) => {handleImageChange(e, 'leftSide'); removeErrorFormField('formImageLeftSide');}} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 9 */}
                <div className={stepNumber === 9 ? "step-box" : "display-none"}>
                    <div className="page-description">{parse(t('NewProgramBillDes'))}</div>

                    {/*<label className={(formImageReceipt !== "" ? "btn-image-file receipt selected" : "btn-image-file receipt") + setErrorFormField('formImageReceipt')} htmlFor="form-image-receipt">*/}
                    {/*    <ExportCurve className="icon" variant="TwoTone" />*/}
                    {/*    {t('UploadYourReceiptPhoto')}*/}
                    {/*    <input type="file" id="form-image-receipt" onChange={(e) => {handleImageChange(e, 'receipt'); removeErrorFormField('formImageReceipt');}} />*/}
                    {/*</label>*/}

                    <div className="price-box">
                        <div className="item"><Moneys className="icon" /><div className="title-price"><span className="title">{t('Price')}:</span><span className="price">{programPrice} {t('PriceUnit')}</span></div></div>
                        <div className="item"><MoneyAdd className="icon" /><div className="title-price"><span className="title">{t('Tax')}({programTax}%):</span><span className="price">{programPriceTax} {t('PriceUnit')}</span></div></div>
                        <div className="item"><WalletMoney className="icon" /><div className="title-price"><span className="title">{t('Total')}:</span><span className="price">{programPriceTotal} {t('PriceUnit')}</span></div></div>
                    </div>
                </div>


                {/* loading */}
                {programsDataLoading || countryDataLoading || coachDataLoading || formLoading ? <LoadingOver /> : null}

                {/* dialog alert */}
                <DialogAlert ref={dialogAlert} />
            </Scrollbars>

            {
                stepNumber >= 4 ?
                    <div className="section-footer">
                        {
                            {
                                // 2:
                                //     <>
                                //         <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                //         <span></span>
                                //     </>,
                                // 3:
                                //     <>
                                //         <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                //         <span></span>
                                //     </>,
                                4:
                                    <>
                                        <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                        <button onClick={handleStep4} type="button" className="btn-custom">{t('Next')}</button>
                                    </>,
                                5:
                                    <>
                                        <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                        <button onClick={handleStep5} type="button" className="btn-custom">{t('Next')}</button>
                                    </>,
                                6:
                                    <>
                                        <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                        <button onClick={handleStep6} type="button" className="btn-custom">{t('Next')}</button>
                                    </>,
                                7:
                                    <>
                                        <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                        <button onClick={handleStep7} type="button" className="btn-custom">{t('Next')}</button>
                                    </>,
                                8:
                                    <>
                                        <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                        <button onClick={handleStep8} type="button" className="btn-custom">{t('Next')}</button>
                                    </>,
                                9:
                                    <>
                                            <button onClick={handleStepBack} type="button" className="btn-custom">{t('Back')}</button>
                                            <button onClick={handleStep9} type="button" className="btn-custom">{t('FinishAndTransferToThePaymentGateway')}</button>
                                    </>
                            }[stepNumber]
                        }
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default NewProgram;
