import React, {useEffect, useRef, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import {Eye, EyeSlash, Mobile} from 'iconsax-react';
import UserApi from "../../services/UserApi";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import imageBg02 from '../../images/bg-02.png';
import "./ForgetPassword.css";
import {useTranslation} from "react-i18next";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import General from "../../utils/General";

//----------------------------------------------------------------------------------------------------------------------

function ForgetPassword()
{
    const dialogAlert = useRef(); // init dialog alert
    const { t, i18n } = useTranslation(); // translator

    const [formPhone, setFormPhone] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [formLoading, setFormLoading] = useState(false);
    const [isStepOne, setIsStepOne] = useState(true);
    const [isForgetPassword, setIsForgetPassword] = useState(false);

    const [formVerifyCode1, setFormVerifyCode1] = useState("");
    const [formVerifyCode2, setFormVerifyCode2] = useState("");
    const [formVerifyCode3, setFormVerifyCode3] = useState("");
    const [formVerifyCode4, setFormVerifyCode4] = useState("");
    const [formErrors, setFormErrors] = useState(null);

    const [verifyCodeTimer, setVerifyCodeTimer] = useState('');
    const [isActiveResend, setIsActiveResend] = useState(true);
    let intervalVerifyCodeTimer = null;
    let timerValue = 0;

    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);
    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // next step
    const submitNext = () =>
    {
        if(isActiveResend)
        {
            if(formPhone !== "" && formPassword.length >= 5 && formPassword.length <= 20) // check data is correct
            {
                setFormLoading(true); // hide btn & show loading

                // send data
                const params = {'phone': formPhone, 'reCaptchaToken': reCaptchaToken};
                UserApi.forgetPasswordSendVerifyCode(params).then
                (
                    function(response)
                    {
                        if(response.status === 200)
                        {
                            clearInterval(googleReCaptchaInterval);

                            //
                            dialogAlert.current.show(t('ForgetPasswordVerifyCodeSentToYourPhone'), "", "success"); // show error
                            setIsStepOne(false);
                            setIsActiveResend(false);

                            // set timer for resend code
                            timerValue = 120;
                            intervalVerifyCodeTimer = setInterval
                            (
                                function()
                                {
                                    timerValue -= 1;
                                    setVerifyCodeTimer('Resend (' + timerValue + ')');

                                    if(timerValue <= 0)
                                    {
                                        clearInterval(intervalVerifyCodeTimer);
                                        setVerifyCodeTimer('Resend');
                                        setIsActiveResend(true);
                                    }
                                },
                                1000
                            );
                        }
                        else
                        {
                            dialogAlert.current.show(t(response.message), "", "error"); // show error
                        }

                        setFormLoading(false); // show btn & hide loading

                        generateRecaptcha();
                    }
                ).catch
                (
                    function(error)
                    {
                        setFormLoading(false); // show btn & hide loading
                        dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error

                        generateRecaptcha();
                    }
                );
            }
            else
            {
                dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error"); // show error

                // check error
                let errors = {};
                if(formPhone === "") errors["formPhone"] = true;
                if(!(formPassword.length >= 5 && formPassword.length <= 20)) errors["formPassword"] = true;

                setFormErrors(errors);
            }
        }
    };

    // send data to server
    const submitForgetPassword = () =>
    {
        const formVerifyCode = formVerifyCode1 + '' + formVerifyCode2 + '' + formVerifyCode3 + '' + formVerifyCode4;

        if(formVerifyCode.length === 4) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'phone': formPhone, 'password': formPassword, 'verifyCode': formVerifyCode, 'reCaptchaToken': reCaptchaToken};
            UserApi.forgetPassword(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        //
                        dialogAlert.current.show(t('NewPasswordSaved'), "", "success"); // show success

                        setIsForgetPassword(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error

                    generateRecaptcha();
                }
            );
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error"); // show error

            // check error
            let errors = {};
            if(formVerifyCode.length !== 4) errors["formVerifyCode"] = true;

            setFormErrors(errors);
        }
    };

    // handle error
    const setErrorFormField = (field) =>
    {
        return (formErrors !== null && formErrors[field] !== undefined) ? " error" : "";
    };

    const removeErrorFormField = (field) =>
    {
        let errors = formErrors;
        if(errors !== null && errors[field] !== undefined) delete errors[field];

        setFormErrors({...errors});
    };

    // next input
    const autoTab = (e: ChangeEvent<HTMLInputElement>) =>
    {
        if(`${e.target.value.length}` === e.target.getAttribute("maxlength"))
        {
            var inputs = document.getElementsByClassName("form-forget-password-verification-code");

            for (let i = 0; i < inputs.length; i++)
            {
                if (e.target === inputs[i])
                {
                    i++;
                    if (i < inputs.length)
                    {
                        let next = inputs[i];
                        next.focus();
                    }
                }
            }
        }
    };

    // redirect to login
    if(isForgetPassword)
    {
        return <Navigate to="/login" />;
    }

    // page content ----------------------------------------------------------------------------------------------------
    return (
        <div className="page-forget-password">
            {
                isStepOne ?
                    <>
                        <div className="col-left">
                            <img src={imageBg02} />
                        </div>

                        <div className="col-right">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">{t('ForgetPassword')}</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type="number" className={"form-input" + setErrorFormField('formPhone')} onKeyPress={() => removeErrorFormField('formPhone')} placeholder={t('PhoneNumber') + '   989123219876'} onChange={(e) => setFormPhone(e.target.value)} />
                                                <Mobile variant="TwoTone" className="icon" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type={passwordType} className={"form-input" + setErrorFormField('formPassword')} onKeyPress={(e) => {removeErrorFormField('formPassword'); General.handleInputOnlyEn(e);}} placeholder={t('NewPassword')} autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                                {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitNext} type="button" className="btn-custom full">{t('Next')}</button>
                                <Link to="/login" className="btn-custom t2 full">{t('Back')}</Link>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-left">
                            <img src={imageBg02} />
                        </div>

                        <div className="col-right">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">{t('Verification')}</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-forget-password-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={() => removeErrorFormField('formVerifyCode')} maxLength="1" value={formVerifyCode1} onChange={(e) => {setFormVerifyCode1(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-forget-password-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={() => removeErrorFormField('formVerifyCode')} maxLength="1" value={formVerifyCode2} onChange={(e) => {setFormVerifyCode2(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-forget-password-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={() => removeErrorFormField('formVerifyCode')} maxLength="1" value={formVerifyCode3} onChange={(e) => {setFormVerifyCode3(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-forget-password-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={() => removeErrorFormField('formVerifyCode')} maxLength="1" value={formVerifyCode4} onChange={(e) => {setFormVerifyCode4(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitForgetPassword} type="button" className="btn-custom full">{t('Submit')}</button>
                                <button onClick={submitNext} type="button" className="btn-custom t2 full">{verifyCodeTimer}</button>
                            </div>
                        </div>
                    </>
            }

            {/* loading */}
            {formLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* dialog alert */}
            <DialogAlert ref={dialogAlert} />
        </div>
    );
}

export default ForgetPassword;
