import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./Chat.css";
import imageUser from "../../images/image-user.png";
import {ArrowRight2, DocumentDownload, Link2, Send} from "iconsax-react";
import ChatApi from "../../services/ChatApi";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

//--------------------------------------------------

function Chat(props)
{
    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.chat.title), activeMenu: Menus.chat.className});

    //
    const dialogAlert = useRef(); // init dialog alert

    const [chatDataLoading, setChatDataLoading] = useState(true);
    const [chatData, setChatData] = useState(null);

    const [formMessage, setFormMessage] = useState("");

    let scrollbarRef;

    // load data
    const loadData = () =>
    {
        setChatDataLoading(true); // show loading

        // get data
        ChatApi.list().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setChatData(response.data.list);
                }

                setChatDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setChatDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);
    useEffect(() => { scrollbarRef.scrollToBottom(); }, [chatData]);

    // send data to server
    const submitMessage = () =>
    {
        if(formMessage !== "") // check data is correct
        {
            setChatDataLoading(true); // hide btn & show loading

            // send data
            ChatApi.store({'message': formMessage}).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        setFormMessage("");
                        loadData();
                    }
                    else
                    {
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                        setChatDataLoading(false); // show btn & hide loading
                    }
                }
            ).catch
            (
                function(error)
                {
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error
                    setChatDataLoading(false); // show btn & hide loading
                }
            );
        }
    };

    // send file to server
    const submitMessageFile = (e) =>
    {
        if(e.target.files !== undefined)
        {
            setChatDataLoading(true); // show loading

            //
            let params = new FormData();
            params.append('file', e.target.files[0]);
            ChatApi.upload(params).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        loadData();
                    }
                    else
                    {
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                        setChatDataLoading(false); // hide loading
                    }
                }
            ).catch
            (
                function(error)
                {
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error
                    setChatDataLoading(false); // hide loading
                }
            );
        }
        else
        {
            dialogAlert.current.show(t('TheSelectedFileIsIncorrect'), "", "error"); // show error
        }
    };

    //
    return (
        <div className="page-content page-program-chat">
            <div className="section-title">
                <div className="title-box">{parse(t('OnlineChat'))}</div>
            </div>

            <div className="page-description m-b-16">{t('SupportDes')}</div>

            <div className="chat-box">
                <div className="message-body">
                    <Scrollbars ref={c => scrollbarRef = c}  autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                        {
                            chatData != null && chatData.length > 0 ?
                                chatData.map
                                (
                                    (chat, i) =>
                                        <div className={chat.isAdmin ? "message-row t2" : "message-row"}>
                                            <img className="user-image" src={imageUser} />

                                            <div className="message-box">
                                                {
                                                    chat.filePath !== "" ?
                                                        <a className="link-file" href={chat.filePath} target="_blank"><DocumentDownload className="icon" variant="TwoTone" /><span className="name">{chat.fileName}</span><span className="size">({chat.fileSize})</span></a>
                                                        :
                                                        <div className="message">{chat.message}</div>
                                                }
                                                <div className="date-time">{chat.dateTime}</div>
                                            </div>
                                        </div>
                                )
                                :
                                null
                        }
                    </Scrollbars>
                </div>

                <div className="message-send-bar">
                    <textarea className="form-textarea" value={formMessage} placeholder={t('TypeYourMessage')} onChange={(e) => setFormMessage(e.target.value)}/>

                    <label htmlFor="message-file" className="btn btn-file">
                        <Link2 className="icon" variant="TwoTone" />
                        <input id="message-file" type="file" className="message-file-input" onChange={submitMessageFile} />
                        {/*<button type="button" className="btn btn-file"><Link2 className="icon" variant="TwoTone" /></button>*/}
                    </label>

                    <button type="button" className="btn btn-submit" onClick={submitMessage}><Send className="icon" variant="TwoTone" /></button>
                </div>
            </div>


            {/* loading */}
            {chatDataLoading ? <LoadingOver /> : null}

            {/* dialog alert */}
            <DialogAlert ref={dialogAlert} />
        </div>
    );
}

export default Chat;
