import React, {useEffect, useRef, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import {ArrowDown2, TickSquare, Mobile, Eye, EyeSlash, UserEdit, Sms} from 'iconsax-react';
import UserApi from "../../services/UserApi";
import CountryApi from "../../services/CountryApi";
import { useAuth } from '../../contexts/Auth';
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import imageBg01 from '../../images/bg-01.png';
import imageBg02 from '../../images/bg-02.png';
import "./Register.css";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import {useTranslation} from "react-i18next";
import General from "../../utils/General";
import {Autocomplete, Box, TextField} from "@mui/material";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

//----------------------------------------------------------------------------------------------------------------------

function Register()
{
    const auth = useAuth();
    const { t, i18n } = useTranslation(); // translator
    const dialogAlert = useRef(); // init dialog alert
    const dialogQuestion = useRef(); // init alert Terms and condition

    const [formName, setFormName] = useState("");
    const [formCountry, setFormCountry] = useState("");
    const [formCountryCode, setFormCountryCode] = useState("");
    const [formPhone, setFormPhone] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [isAcceptTermsCondition, setIsAcceptTermsCondition] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [isStepOne, setIsStepOne] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [formVerifyCode1, setFormVerifyCode1] = useState("");
    const [formVerifyCode2, setFormVerifyCode2] = useState("");
    const [formVerifyCode3, setFormVerifyCode3] = useState("");
    const [formVerifyCode4, setFormVerifyCode4] = useState("");

    const [countryDataLoading, setCountryDataLoading] = useState(true);
    const [countryData, setCountryData] = useState(null);

    const [verifyCodeTimer, setVerifyCodeTimer] = useState('');
    const [isActiveResend, setIsActiveResend] = useState(true);
    let intervalVerifyCodeTimer = null;
    let timerValue = 0;

    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);
    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // load data
    const loadData = () =>
    {
        setCountryDataLoading(true); // show loading

        // get data
        CountryApi.list().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setCountryData(response.data.list);
                }

                setCountryDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCountryDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // next step
    const submitNext = () =>
    {
        if(isActiveResend)
        {
            if(formName !== "" && formCountry > 0 && formPhone !== "" && formPassword.length >= 5 && formPassword.length <= 20 && isAcceptTermsCondition) // check data is correct
            {
                setFormLoading(true); // hide btn & show loading

                // send data
                const params = {'phone': formPhone, 'countryId': formCountry, 'reCaptchaToken': reCaptchaToken};
                UserApi.registerSendVerifyCode(params).then
                (
                    function(response)
                    {
                        if(response.status === 200)
                        {
                            clearInterval(googleReCaptchaInterval);

                            //
                            dialogAlert.current.show(t('RegisterVerifyCodeSentToYourPhone'), "", "success"); // show success
                            setIsStepOne(false);
                            setIsActiveResend(false);

                            // set timer for resend code
                            timerValue = 120;
                            intervalVerifyCodeTimer = setInterval
                            (
                                function()
                                {
                                    timerValue -= 1;
                                    setVerifyCodeTimer('Resend (' + timerValue + ')');

                                    if(timerValue <= 0)
                                    {
                                        clearInterval(intervalVerifyCodeTimer);
                                        setVerifyCodeTimer('Resend');
                                        setIsActiveResend(true);
                                    }
                                },
                                1000
                            );
                        }
                        else
                        {
                            dialogAlert.current.show(t(response.message), "", "error"); // show error
                        }

                        setFormLoading(false); // show btn & hide loading

                        generateRecaptcha();
                    }
                ).catch
                (
                    function(error)
                    {
                        setFormLoading(false); // show btn & hide loading
                        dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error

                        generateRecaptcha();
                    }
                );
            }
            else
            {
                dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error"); // show error

                // check error
                let errors = {};
                if(formName === "") errors["formName"] = true;
                if(formCountry <= 0) errors["formCountry"] = true;
                if(formPhone === "") errors["formPhone"] = true;
                if(!(formPassword.length >= 5 && formPassword.length <= 20)) errors["formPassword"] = true;
                if(!isAcceptTermsCondition) errors["isAcceptTermsCondition"] = true;

                setFormErrors(errors);
            }
        }
    };

    // back step
    const submitBack = () =>
    {
        setIsStepOne(true);
    };

    // send data to server
    const submitRegister = () =>
    {
        const formVerifyCode = formVerifyCode1 + '' + formVerifyCode2 + '' + formVerifyCode3 + '' + formVerifyCode4;

        if(formVerifyCode.length === 4) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'name': formName, 'countryId': formCountry, 'phone': formPhone, 'password': formPassword, 'verifyCode': formVerifyCode, 'reCaptchaToken': reCaptchaToken};
            UserApi.register(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        // change language to fa if phone have 98
                        i18n.changeLanguage((formCountryCode === '+98' || formCountryCode === '98') ? 'fa-IR' : 'en-US');

                        //
                        dialogAlert.current.show(t('RegisterDoneSuccessfully'), "", "success"); // show success

                        auth.authentication({user: response.data.user, name: response.data.name, token: response.data.token, isRemember: false});
                        setIsRegister(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        dialogAlert.current.show(t(response.message), "", "error"); // show error
                    }

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    dialogAlert.current.show(t('ThereIsAProblem'), "", "error"); // show error

                    generateRecaptcha();
                }
            );
        }
        else
        {
            dialogAlert.current.show(t('PleaseFillOutAllFields'), "", "error"); // show error

            // check error
            let errors = {};
            if(formVerifyCode.length !== 4) errors["formVerifyCode"] = true;

            setFormErrors(errors);
        }
    };

    // Terms and condition modal
    const handleTermsConditionModalOpen = () =>
    {
        let tmpIsAcceptTermsCondition = isAcceptTermsCondition;
        setTimeout(function() { setIsAcceptTermsCondition(tmpIsAcceptTermsCondition); }, 1000);

        dialogQuestion.current.show();
    };
    const handleTermsConditionModalClose = () =>
    {
        dialogQuestion.current.hide();
    };

    // handle error
    const setErrorFormField = (field) =>
    {
        return (formErrors !== null && formErrors[field] !== undefined) ? " error" : "";
    };

    const removeErrorFormField = (field) =>
    {
        let errors = formErrors;
        if(errors !== null && errors[field] !== undefined) delete errors[field];

        setFormErrors({...errors});
    };

    // next input
    const autoTab = (e: ChangeEvent<HTMLInputElement>) =>
    {
        if(`${e.target.value.length}` === e.target.getAttribute("maxlength"))
        {
            var inputs = document.getElementsByClassName("form-register-verification-code");

            for (let i = 0; i < inputs.length; i++)
            {
                if (e.target === inputs[i])
                {
                    i++;
                    if (i < inputs.length)
                    {
                        let next = inputs[i];
                        next.focus();
                    }
                }
            }
        }
    };

    // redirect to panel if user loggedIn
    if(isRegister)
    {
        return <Navigate to="/programs" />;
    }

    // page content ----------------------------------------------------------------------------------------------------
    return (
        <div className="page-register">
            {
                isStepOne ?
                    <>
                        <div className="col-left">
                            <img src={imageBg01} />
                        </div>

                        <div className="col-right step-1">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">{t('Register')}</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input" + setErrorFormField('formName')} onKeyPress={(e) => {removeErrorFormField('formName'); General.handleInputOnlyEn(e);}} placeholder={t('FullName')} onChange={(e) => setFormName(e.target.value)} />
                                                <UserEdit variant="TwoTone" className="icon" />
                                            </div>
                                        </div>

                                        <div className="col-5">
                                            <div className="form-input-box form-select-box">
                                                {
                                                    countryData != null && countryData.length > 0 ?
                                                        <Autocomplete
                                                            getOptionLabel={(option) => option.label}
                                                            // value={formCountry}
                                                            className={"form-select form-select-mui" + setErrorFormField('formCountry')}
                                                            onChange={(event, newValue) => {setFormCountry(newValue.id); setFormCountryCode(newValue.phoneCode); removeErrorFormField('formCountry');}}
                                                            disablePortal
                                                            options={countryData}
                                                            sx={{ width: 300 }}
                                                            renderOption={(props, option) => (<Box component="li" className="form-select-mui-item" {...props}><img loading="lazy" width="20" src={option.flag} alt=""/>{option.label} ({option.phoneCode})</Box>)}
                                                            renderInput={(params) => <TextField {...params} placeholder={t('Country')} inputProps={{...params.inputProps, autoComplete: 'new-password'}} />}
                                                            popupIcon={<ArrowDown2 variant="TwoTone" className="icon" />}
                                                        />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                        <div className="col-7">
                                            <div className="form-input-box">
                                                <input type="number" className={"form-input" + setErrorFormField('formPhone')} onKeyPress={(e) => {removeErrorFormField('formPhone'); General.handleInputOnlyEn(e);}} placeholder={t('PhoneNumber') + '   9123219876'} onChange={(e) => setFormPhone(e.target.value)} />
                                                <Mobile variant="TwoTone" className="icon" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type={passwordType} className={"form-input" + setErrorFormField('formPassword')} onKeyPress={(e) => {removeErrorFormField('formPassword'); General.handleInputOnlyEn(e);}} placeholder={t('Password')} autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                                {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="">
                                                <label className={"form-checkbox" + setErrorFormField('isAcceptTermsCondition')} onClick={() => {setIsAcceptTermsCondition(!isAcceptTermsCondition); removeErrorFormField('isAcceptTermsCondition');}}>{isAcceptTermsCondition ? <TickSquare variant="Bulk" className="icon" /> : <span className="icon"></span>} {t('TermsCondition')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="link-custom border-bottom btn-terms-condition" onClick={handleTermsConditionModalOpen}>{t('View')}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitNext} type="button" className="btn-custom full">{t('Next')}</button>
                                <Link to="/login" className="btn-custom t2 full">{t('DoYouHaveAccount')}</Link>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-left">
                            <img src={imageBg02} />
                        </div>

                        <div className="col-right step-2">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">{t('Verification')}</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-register-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={(e) => {removeErrorFormField('formVerifyCode'); General.handleInputOnlyEn(e);}} maxLength="1" value={formVerifyCode1} onChange={(e) => {setFormVerifyCode1(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-register-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={(e) => {removeErrorFormField('formVerifyCode'); General.handleInputOnlyEn(e);}} maxLength="1" value={formVerifyCode2} onChange={(e) => {setFormVerifyCode2(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-register-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={(e) => {removeErrorFormField('formVerifyCode'); General.handleInputOnlyEn(e);}} maxLength="1" value={formVerifyCode3} onChange={(e) => {setFormVerifyCode3(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className={"form-input no-icon text-center form-register-verification-code" + setErrorFormField('formVerifyCode')} onKeyPress={(e) => {removeErrorFormField('formVerifyCode'); General.handleInputOnlyEn(e);}} maxLength="1" value={formVerifyCode4} onChange={(e) => {setFormVerifyCode4(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitRegister} type="button" className="btn-custom full">{t('Submit')}</button>
                                <button onClick={submitNext} type="button" className="btn-custom t2 full">{verifyCodeTimer}</button>
                                {isActiveResend ? <button onClick={submitBack} type="button" className="btn-custom t2 full">{t('Back')}</button> : null}
                            </div>
                        </div>
                    </>
            }

            {/* loading */}
            {formLoading || countryDataLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* dialog alert */}
            <DialogAlert ref={dialogAlert} />

            {/* Terms and condition */}
            <DialogQuestion ref={dialogQuestion} accept={handleTermsConditionModalClose} title={t('TermsCondition')} des={t('TermsConditionDes').replace(/\n/g, "<br />")} isFull={true} buttonType="" buttonAcceptText="" buttonCloseText="" />
        </div>
    );
}

export default Register;
