import {useTranslation} from "react-i18next";
import imageDialogWarning from '../../images/dialog-warning.svg';
import parse from "html-react-parser";
import React from "react";
import "./AlertDataEmpty.css";

//--------------------------------------------------

function AlertDataEmpty(props)
{
    const { t, i18n } = useTranslation(); // translator

    //
    return (
        // <Alert className="alert-data-empty" severity="warning">{t('DataNotFound')}</Alert>
        <div className="page-alert error">
            <div className="image"><img src={imageDialogWarning}  alt="" /></div>
            <div className="des">{props.des !== undefined ? props.des : parse(t('DataNotFound'))}</div>
        </div>
    );
}

export default AlertDataEmpty;
